import {firestore} from "@/firebase/firebaseConfig";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {updateDoc, FieldValue, serverTimestamp} from "@firebase/firestore";
import {currentUser} from "@/firebase/firebaseAuth";
import {updateProfile} from "@firebase/auth";

export interface UserProfile {
    uid: string;
    displayName?: string;
    firstName?: string;
    surname?: string;
    email?: string;
    emailVerified?: boolean;
    photoURL?: string;
    referralMethod?: string;
    dateCreated?: Date;
    lastUpdated?: Date;
    onboardEditScreen: boolean;
}

export interface FirestoreUserProfile {
    displayName?: string;
    firstName?: string;
    surname?: string;
    email?: string;
    photoURL?: string;
    referralMethod?: string;
    onboardEditScreen?: boolean;
    dateCreated: FieldValue | Date;
    lastUpdated: FieldValue | Date;
}

/**
 * Get the UserProfile from Firestore. Does not include any data from the AuthUser object
 * See getCombinedUserProfile for a version that combines the AuthUser and FirestoreUserProfile
 * @param uid
 */
export const getUserProfile = async (uid: string): Promise<UserProfile | null> => {
    return getDoc(doc(firestore, 'UserProfiles', uid))
        .then((doc) => {
            if (doc.exists()) {
                return mapToLocalUserProfile(uid, doc.data())
            } else {
                return null
            }
        })
        .catch((error) => {
            console.error("Error getting user profile:", error);
            throw error
        })
}

/**
 * Update the UserProfile in Firestore. Does not include any data from the AuthUser object
 * See setCombinedUserProfile for a version that combines the AuthUser and FirestoreUserProfile
 * @param profile
 */
export const updateUserProfile = async (profile: UserProfile): Promise<UserProfile> => {

    //Map the UserProfile to a FirestoreUserProfile
    const uid = profile.uid
    const payload = {...mapToFirestoreUserProfile(profile)}

    //Check if the UserProfile exists.
    // If it does then simply update the record with included fields, if not then create a new record
    return getUserProfile(profile.uid)
        .then((dbProfile) => {
            if (dbProfile) {
                console.log('Updating user profile', payload)
                return updateDoc(doc(firestore, 'UserProfiles', uid), payload)
                    .then(() => {
                        console.log('User profile updated')
                        return profile
                    })
                    .catch((error) => {
                        console.error("Error updating user profile:", error);
                        throw error
                    })
            } else {
                console.log('Creating user profile')
                return setDoc(doc(firestore, 'UserProfiles', uid), payload)
                    .then(() => {
                        return profile
                    })
                    .catch((error) => {
                        console.error("Error updating user profile:", error);
                        throw error
                    })
            }
        })
}

function mapToFirestoreUserProfile(profile: UserProfile): FirestoreUserProfile {

    const doc: FirestoreUserProfile = {
        displayName: (profile.displayName || '').trim(),
        email: profile.email,
        photoURL: profile.photoURL,
        referralMethod: profile.referralMethod,
        dateCreated: profile.dateCreated || serverTimestamp(),
        lastUpdated: serverTimestamp(),
        onboardEditScreen: profile.onboardEditScreen
    }

    if (doc.displayName) {
        const nameParts = doc.displayName.split(" ")

        if (nameParts.length == 1) {
            doc.firstName = nameParts[0]
        } else if (nameParts.length > 1) {
            doc.firstName = nameParts[0]
            doc.surname = doc.displayName.replace(nameParts[0], "").trim()
        }
    }

    return doc
}

function mapToLocalUserProfile(uid: string, doc: any): UserProfile {
    return {
        uid: uid,
        displayName: doc.displayName,
        firstName: doc.firstName,
        surname: doc.surname,
        email: doc.email,
        emailVerified: doc.emailVerified,
        photoURL: doc.photoURL,
        referralMethod: doc.referralMethod,
        dateCreated: doc.dateCreated ? doc.dateCreated.toDate() : new Date(),
        lastUpdated: doc.lastUpdated ? doc.lastUpdated.toDate() : new Date(),
        onboardEditScreen: doc.onboardEditScreen
    }
}

/**
 * Gets a version of the user profile that combines the AuthUser and FirestoreUserProfile into one object
 * @param uid
 */
export const getCombinedUserProfile = async (uid: string): Promise<UserProfile | null> => {
    const userProfile = {uid: uid} as UserProfile

    const authUser = currentUser()

    if (authUser && authUser.uid === uid) {
        userProfile.uid = authUser.uid
        userProfile.displayName = authUser.displayName || ''
        userProfile.email = authUser.email || ''
        userProfile.emailVerified = authUser.emailVerified || false
        userProfile.photoURL = authUser.photoURL || ''
    }

    const firestoreUserProfile = await getUserProfile(uid)
    if (firestoreUserProfile) {
        //If the displayName or photoUrl are not set then use the FirestoreUserProfile values
        userProfile.displayName = !userProfile.displayName ? firestoreUserProfile.displayName : userProfile.displayName
        userProfile.photoURL = !userProfile.displayName ? firestoreUserProfile.photoURL : userProfile.photoURL

        //First name, surnmae and referral method are only stored in the FirestoreUserProfile
        userProfile.firstName = firestoreUserProfile.firstName
        userProfile.surname = firestoreUserProfile.surname
        userProfile.referralMethod = firestoreUserProfile.referralMethod
        userProfile.dateCreated = firestoreUserProfile.dateCreated
        userProfile.lastUpdated = firestoreUserProfile.lastUpdated
        userProfile.onboardEditScreen = firestoreUserProfile.onboardEditScreen
    }

    return userProfile
}

/**
 * Updates the AuthUser and FirestoreUserProfile with the values from the UserProfile object
 * @param user
 */
export function setCombinedUserProfile(user: UserProfile) {
    const authUser = currentUser()
    if (!authUser) {
        throw new Error("User is not signed in")
    }

    return updateProfile(authUser, {
        displayName: user.displayName,
        photoURL: user.photoURL
    }).then(() => {
        return updateUserProfile(user)
    })
}

/**
 * Updates the onboarding status for the user
 * @param uid
 * @param seen
 */
export function updateOnboardingStatus(uid: string, seen: boolean): Promise<void> {
    return updateDoc(doc(firestore, "UserProfiles", uid), {
        onboardEditScreen: true,
        lastUpdated: serverTimestamp(),
    }).then(() => {
        console.log('Updated userProfile seenOnboardEditScreen status for user', uid)
    })
}